import { Dialog } from "demo/components/ui"
import { closeFileDialog, openFileDialog } from "demo/store/commonReducer"
import { useState } from "react"
import { GrDocumentImage, GrDocumentMissing, GrDocumentPdf } from "react-icons/gr"
import { useDispatch, useSelector } from "react-redux"

export default function CommonOpenFile({ file, showFile = true }) {
    const dispatch = useDispatch()
    const { isOpenFileDialog } = useSelector((state) => state.common)

    const onDialogClose = () => {
        dispatch(closeFileDialog())
    }

    const isImageFile = ["jpeg", "JPEG", "jpg", "JPG", "png", "PNG", "webp", "WEBP"].includes(file.url?.split(".").pop());
    const isPdfFile = ["pdf", "PDF"].includes(file.url?.split(".").pop());
    
    return (
        <>
            {showFile && <div className="flex items-center justify-center gap-2 pointer" onClick={() => dispatch(openFileDialog())}>
                {isImageFile ? 
                    <GrDocumentImage className='text-3xl icon-frame' /> 
                : isPdfFile ?
                    <GrDocumentPdf className='text-3xl icon-frame' /> 
                :   <GrDocumentMissing className='text-3xl icon-frame'/>
                }
            </div>}
            <Dialog
                isOpen={isOpenFileDialog}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
            >
                <h5 className="mb-4">{file.display_name}&nbsp;</h5>
                {isImageFile ? 
                    <img src={file.url} />
                :
                    <iframe
                        src={file.url}
                        style={{ width: "100%", height: "100%", minHeight: "600px" }}
                    />
                }
            </Dialog>
        </>
    )
}