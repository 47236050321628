import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getJobList = createAsyncThunk('getJobList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getJobList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const createJob = createAsyncThunk('createJob', async (payload, thunkAPI) => {
  try {
    const response = await apiService.createJob(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getJobInfo = createAsyncThunk('getJobInfo', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getJobInfo(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const updateJob = createAsyncThunk('updateJob', async (payload, thunkAPI) => {
  try {
    const response = await apiService.updateJob(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getOrderOption = createAsyncThunk('getOrderOption', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getOrderOption(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);


export const initialData = {
  jobList: [],
  jobInfo: {},
  jobInstallation: [],
  orderOption: [],

  infoLoading: false,
  listLoading: true,
  formLoading: false,
  optionLoading: false,
  // actionStatus: {},
  // isOpenActionDialog: false,

  jobSort: {
    activePage: 1,
    start: 0,
    length: 10,
    search: '',
    total: 0,
    status: null
  },
}

const dataSlice = createSlice({
  name: "job/data",
  initialState: initialData,
  reducers: {
    // openActionDialog: (state) => {
    //   state.isOpenActionDialog = true;
    // },
    // closeActionDialog: (state) => {
    //   state.isOpenActionDialog = false;
    // },
    setActionSlip: (state, action) => {
      state.actionSlip = action.payload;
    },
    setJobInstallation: (state, action) => {
      state.jobInstallation = action.payload;
    },
    // setActionStatus: (state, action) => {
    //   state.actionStatus = action.payload;
    // },
    setJobSort: (state, action) => {
      state.jobSort = action.payload;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },
  },
  extraReducers: {
    //list
    [getJobList.rejected]: (state) => {
      state.listLoading = false;
    },
    [getJobList.fulfilled]: (state, action) => {
      state.listLoading = false;
      state.jobList = action.payload.data.aaData;
      state.jobSort.total = action.payload.data.iTotalDisplayRecords;
    },
    [getJobList.pending]: (state) => {
      state.listLoading = true;
    },

    [getOrderOption.rejected]: (state) => {
      state.optionLoading = false;
    },
    [getOrderOption.fulfilled]: (state, action) => {
      state.optionLoading = false;
      state.orderOption = action.payload.data;
    },
    [getOrderOption.pending]: (state) => {
      state.optionLoading = true;
    },

    //info
    [getJobInfo.rejected]: (state) => {
      state.infoLoading = false;
    },
    [getJobInfo.fulfilled]: (state, action) => {
      state.infoLoading = false;
      state.jobInfo = action.payload.data;
      state.jobInstallation = action.payload.data?.service_installations;
    },
    [getJobInfo.pending]: (state) => {
      state.infoLoading = true;
    },

    //form
    [createJob.rejected]: (state) => {
      state.formLoading = false;
    },
    [createJob.fulfilled]: (state, action) => {
      state.formLoading = false;
    },
    [createJob.pending]: (state) => {
      state.formLoading = true;
    },

    [updateJob.rejected]: (state) => {
      state.updateLoading = false;
    },
    [updateJob.fulfilled]: (state, action) => {
      state.updateLoading = false;
    },
    [updateJob.pending]: (state) => {
      state.updateLoading = true;
    },
  }
});

export const { 
  openActionDialog,
  closeActionDialog,
  setActionSlip,
  setActionStatus,
  setJobSort,
  setJobInstallation
} = dataSlice.actions;

export default dataSlice.reducer;

