import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
    setAccessoriesSort,
    setAlacarteSort,
    setOtherSort,
    setPackageSort,
} from 'demo/store/serviceReducer'
import { setUserSort } from 'demo/store/userReducer'
import { setJobSort } from 'demo/store/jobReducer'
import { setReportSort } from 'demo/store/reportReducer'
import Badge from '../Badge'

const MenuItem = React.forwardRef((props, ref) => {
    const dispatch = useDispatch()
    const { accessRole, newOrderCount } = useSelector((state) => state.common)

    const {
        asElement: Component,
        children,
        className,
        disabled,
        eventKey,
        isActive,
        menuItemHeight,
        onSelect,
        style,
        variant,
        collapse,
        ...rest
    } = props

    const menuItemActiveClass = `menu-item-active`
    const menuItemHoverClass = `menu-item-hoverable`
    const disabledClass = 'menu-item-disabled'
    const menuItemClass = classNames(
        'menu-item',
        `menu-item-${variant}`,
        isActive && menuItemActiveClass,
        disabled && disabledClass,
        !disabled && menuItemHoverClass,
        className
    )

    const hanldeOnClick = (e) => {
        if (onSelect) {
            onSelect(eventKey, e)
        }

        dispatch(
            setPackageSort({
                activePage: 1,
                start: 0,
                length: 5,
                total: 0,
                search: '',
                body_type: '',
            })
        )

        dispatch(
            setAlacarteSort({
                activePage: 1,
                start: 0,
                length: 5,
                total: 0,
                search: '',
                body_type: '',
            })
        )

        dispatch(
            setAccessoriesSort({
                activePage: 1,
                start: 0,
                length: 10,
                total: 0,
                search: '',
            })
        )

        dispatch(
            setReportSort({
                activePage: 1,
                start: 0,
                length: 10,
                total: 0,
                search: '',
                total: 0,
                type: 'agent',
                payment_slip: 'no',
            })
        )

        dispatch(
            setOtherSort({
                activePage: 1,
                start: 0,
                length: 10,
                total: 0,
                search: '',
            })
        )

        dispatch(
            setJobSort({
                activePage: 1,
                start: 0,
                length: 10,
                total: 0,
                search: '',
                status: null,
            })
        )

        dispatch(
            setUserSort({
                activePage: 1,
                start: 0,
                length: 10,
                total: 0,
                search: '',
                type:
                    accessRole === 'admin'
                        ? 'operation'
                        : accessRole === 'superadmin'
                        ? 'admin'
                        : 'user',
            })
        )
    }

    return (
        <Component
            ref={ref}
            className={menuItemClass}
            style={{ height: `${menuItemHeight}px`, ...style }}
            onClick={hanldeOnClick}
            {...rest}
        >
            {children}
            {!collapse && eventKey === 'report.order' && newOrderCount > 0 && (
                <Badge content={newOrderCount} innerClass="bg-red-600" />
            )}
        </Component>
    )
})

MenuItem.defaultProps = {
    asElement: 'div',
    menuItemHeight: 35,
    variant: 'light',
}

MenuItem.propTypes = {
    asElement: PropTypes.string,
    menuItemHeight: PropTypes.number,
    disabled: PropTypes.bool,
    isActive: PropTypes.bool,
    eventKey: PropTypes.string,
    variant: PropTypes.oneOf(['light', 'dark', 'themed', 'transparent']),
}

export default MenuItem
